import { Portal } from 'react-portal';

const DOM_ID = 'admin-bar';


export default function AdminBar({ className, ...props }) {
  return (
    <div className="py-1 h-full">
      <div className={`
        h-full flex items-center justify-center items-stretch
        bg-amber-300 dark:bg-amber-600 rounded
        ${className}
      `}>
        <div
          id={DOM_ID}
          className={`
            flex items-center
            text-sm font-medium
            text-gray-300 dark:text-gray-800
            dark-stripes gap-4
          `}
          {...props}
        />
      </div>

    </div>
  );
}


export function AdminBarPortal(props) {
  return (
    <Portal {...props} node={document && document.getElementById(DOM_ID)} />
  );
}
