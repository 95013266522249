import { Portal } from 'react-portal';

const DOM_ID = 'payment-bar';


export default function PaymentBar({ className, ...props }) {
  return (
    <div
      id={DOM_ID}
      className={`
        flex items-center h-full text-white text-sm font-medium
        ${className}
      `}
      {...props}
    />
  );
}


export function PaymentBarPortal(props) {
  return (
    <Portal {...props} node={document && document.getElementById(DOM_ID)} />
  );
}
