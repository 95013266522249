import { Link, useLocation } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/24/outline';


export default function MenuSignIn() {
  const { pathname } = useLocation();
  return (
    <Link to={`/login?next=${pathname}`} className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
      <span className="sr-only">Open user menu</span>
      <UserIcon className="h-8 w-8 rounded-full" />
    </Link>
  );
}
